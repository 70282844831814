import React from 'react'

import { PartitionOutlined } from '@ant-design/icons'
import clsx from 'clsx'

import { Button } from 'components/Button'

import type {
  MasterDuplicateIndicatorProps,
  MasterDuplicateIndicatorFullProps
} from './MasterDuplicateIndicator.types'

import {
  buttonStyles,
  buttonStylesMaster,
  buttonStylesDuplicate,
  buttonLabelStylesMaster,
  buttonLabelStylesDuplicate,
  buttonIconStylesMaster,
  buttonIconStylesDuplicate,
  fullIndicatorStyles,
  fullIndicatorStylesMaster,
  fullIndicatorStylesDuplicate,
  buttonIconFullStyles
} from './MasterDuplicateIndicator.module.css'

export const MasterDuplicateIndicatorFull = (props: MasterDuplicateIndicatorFullProps) => {
  const { isMaster } = props

  return isMaster
    ? (
      <div className={clsx(fullIndicatorStyles, fullIndicatorStylesMaster)}>
        <PartitionOutlined className={clsx(buttonIconStylesMaster, buttonIconFullStyles)} />
        <span className={buttonLabelStylesMaster}>
          Master Profile
        </span>
      </div>
    ) : (
      <div className={clsx(fullIndicatorStyles, fullIndicatorStylesDuplicate)}>
        <PartitionOutlined className={clsx(buttonIconStylesDuplicate, buttonIconFullStyles)} />
        <span className={buttonLabelStylesDuplicate}>
          Duplicate Profile
        </span>
      </div>
    )
}

const MasterDuplicateIndicator = (props: MasterDuplicateIndicatorProps) => {
  const { isMaster, showDetails } = props

  return isMaster
    ? (
      <Button
        icon={<PartitionOutlined className={buttonIconStylesMaster} />}
        className={clsx(buttonStyles, buttonStylesMaster)}
        size="small"
        type="primary"
        onClick={showDetails}
      >
        <span className={buttonLabelStylesMaster}>
          Master Profile
        </span>
      </Button>
    ) : (
      <Button
        icon={<PartitionOutlined className={buttonIconStylesDuplicate} />}
        className={clsx(buttonStyles, buttonStylesDuplicate)}
        size="small"
        type="primary"
        onClick={showDetails}
      >
        <span className={buttonLabelStylesDuplicate}>
          Duplicate Profile
        </span>
      </Button>
    )
}

export default MasterDuplicateIndicator
