import React, { useState } from 'react'

import { EllipsisOutlined } from '@ant-design/icons'
import clsx from 'clsx'

import { MarkProfileDuplicatesModal } from '../../containers'
import { FxtStatusModal } from '../FxtStatusModal'
import { SnoozeModal } from '../SnoozeModal'

import { Button } from 'components/Button'
import { Dropdown } from 'components/Dropdown'
import {
  DuplicateIcon,
  MarkFxtIcon,
  RemoveFxtIcon,
  SnoozeIcon
} from 'components/Svg'
import { Tooltip } from 'components/Tooltip'

import type {
  FxtTextEntries,
  FxtTextType,
  IActionDropdownProps,
  IOverlayProps
} from './ActionDropdown.types'

import {
  threeDotButtonStyles,
  overlayListItemStyles,
  overlayListStyles,
  snoozedItemStyles,
  notSnoozedItemStyles,
  cannotSnoozeItemStyles
} from './ActionDropdown.module.css'

const fxtDropdownTexts: Record<FxtTextType, FxtTextEntries> = {
  fxt: {
    add: 'Mark as FXT',
    remove: 'Remove FXT'
  },
  xTeamer: {
    add: 'Mark as Future X-Teamer',
    remove: 'Remove Future X-Teamer'
  }
}

const Overlay = (props: IOverlayProps) => {
  const {
    profileSnooze,
    profileStatus,
    deleteProfileSnooze,
    setIsSnoozeModalOpen,
    setIsMarkDuplicatesModalOpen,
    setIsFxtModalOpen,
    featureFlags,
    isFxt
  } = props

  const isMarkDuplicatesEnabled = Boolean(featureFlags?.RELEASE_MARK_DUPLICATES)
  const isFxtSettingEnabled = Boolean(
    featureFlags?.RELEASE_FXT_IMPROVEMENTS ||
    featureFlags?.RELEASE_XTEAMER_STATUS
  )

  const fxtTextType: FxtTextType = featureFlags?.RELEASE_XTEAMER_STATUS ? 'xTeamer' : 'fxt'

  const isSnoozed = Boolean(profileSnooze?.isSnoozed)
  const label = isSnoozed ? 'End Snooze' : 'Snooze'

  const canBeSnoozedOrUnsnoozed = !profileStatus?.status || isSnoozed

  const onSnoozeClick = () => isSnoozed
    ? deleteProfileSnooze(profileSnooze?.id as number)
    : setIsSnoozeModalOpen(true)

  const snoozeOption = canBeSnoozedOrUnsnoozed
    ? (
      <div
        className={
          clsx(
            overlayListItemStyles,
            isSnoozed
              ? snoozedItemStyles
              : notSnoozedItemStyles
          )
        }
        onClick={onSnoozeClick}
      >
        <SnoozeIcon /> {label}
      </div>
    ) : (
      <Tooltip
        className={cannotSnoozeItemStyles}
        placement="leftTop"
        title={`You cannot snooze a profile that has a "${profileStatus.status}" availability status.`}
      >
        <SnoozeIcon /> Snooze
      </Tooltip>
    )

  return (
    <div className={overlayListStyles}>
      {snoozeOption}

      {isMarkDuplicatesEnabled && (
        <div
          className={overlayListItemStyles}
          onClick={() => setIsMarkDuplicatesModalOpen(true)}
        >
          <DuplicateIcon /> Mark Duplicates
        </div>
      )}

      {isFxtSettingEnabled &&
        (
          <div
            className={overlayListItemStyles}
            onClick={() => setIsFxtModalOpen(true)}
          >
            {isFxt
              ? <>
                <RemoveFxtIcon /> {fxtDropdownTexts[fxtTextType].remove}
              </>
              : <>
                <MarkFxtIcon /> {fxtDropdownTexts[fxtTextType].add}
              </>
            }
          </div>
        )}
    </div>
  )
}

const ActionDropdown = (props: IActionDropdownProps) => {
  const {
    profile,
    duplicates,
    createSnoozeIsLoading,
    deleteSnoozeIsLoading,
    roleCandidatesIsLoading,
    createProfileSnooze,
    createSnoozeError,
    markProfileDuplicates,
    markDuplicatesIsLoading,
    isFxtStatusLoading,
    updateFxtStatus,
    isFxt
  } = props
  const [isSnoozeModalOpen, setIsSnoozeModalOpen] = useState(false)
  const [isMarkDuplicatesModalOpen, setIsMarkDuplicatesModalOpen] = useState(false)
  const [isFxtModalOpen, setIsFxtModalOpen] = useState(false)

  return (
    <>
      <Dropdown
        trigger={['click']}
        placement="bottomRight"
        overlay={
          <Overlay
            profileSnooze={profile.profileSnooze}
            setIsSnoozeModalOpen={setIsSnoozeModalOpen}
            setIsMarkDuplicatesModalOpen={setIsMarkDuplicatesModalOpen}
            setIsFxtModalOpen={setIsFxtModalOpen}
            {...props}
          />
        }
      >
        <Button
          className={threeDotButtonStyles}
          type="primary"
          icon={<EllipsisOutlined />}
          loading = {
            roleCandidatesIsLoading ||
            createSnoozeIsLoading ||
            deleteSnoozeIsLoading ||
            isFxtStatusLoading
          }
        />
      </Dropdown>
      <SnoozeModal
        visible={isSnoozeModalOpen}
        createProfileSnooze={createProfileSnooze}
        closeModal={() => setIsSnoozeModalOpen(false)}
        isLoading={roleCandidatesIsLoading || createSnoozeIsLoading}
        error={createSnoozeError}
      />
      {isMarkDuplicatesModalOpen &&
        <MarkProfileDuplicatesModal
          visible={isMarkDuplicatesModalOpen}
          profile={profile}
          originalDuplicates={duplicates}
          markProfileDuplicates={markProfileDuplicates}
          markDuplicatesIsLoading={markDuplicatesIsLoading}
          closeModal={() => setIsMarkDuplicatesModalOpen(false)}
        />
      }
      {isFxtModalOpen &&
        <FxtStatusModal
          visible={isFxtModalOpen}
          closeModal={() => setIsFxtModalOpen(false)}
          updateFxtStatus={updateFxtStatus}
          isFxt={Boolean(isFxt)}
          profileId={profile.id}
        />
      }
    </>
  )
}

export default ActionDropdown
