
import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { profileStatuses } from 'components/ProfileStatusTag/constants'

import { getProfileStatus } from 'services/profileStatus'

import { useProfileId } from 'redesign/hooks/useProfileId'

import type { UseProfileStatusReturnProps } from './useProfileStatus.types'

const useProfileStatus = (): UseProfileStatusReturnProps => {
  const { profileId } = useProfileId()

  const { data: profileStatusesData, isLoading, refetch } = useQuery({
    queryKey: ['status', profileId],
    queryFn: () => getProfileStatus(profileId)
  })

  const data = useMemo(() => {
    const [first] = profileStatusesData?.data || []
    return first || profileStatuses.ready
  }, [profileStatusesData])

  return {
    data,
    isLoading,
    refetch,
  }
}
export default useProfileStatus
