
import React, { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { Select } from 'components/Select'

import { listNoteTags } from 'services/noteTags'

import { arrayToSelectOptions } from 'utils/helpers'

const NoteTagsField = ({ ...props }) => {
  const { data } = useQuery({
    queryKey: ['noteTags'],
    queryFn: () => listNoteTags({ params: { _limit: 999 } })
  })
  const noteTags = useMemo(
    () => arrayToSelectOptions(data?.data, 'id', 'slug') || [],
    [data]
  )

  return (
    <Select
      mode="multiple"
      showSearch
      style={{ width: '100%' }}
      options={noteTags}
      optionFilterProp="label"
      {...props}
    />
  )
}

export default NoteTagsField
