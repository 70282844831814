
import React, { useState } from 'react'

import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'

import { ExperiencesForm } from '../ExperiencesForm'

import { Button } from 'components/Button'
import TextEditorPreview from 'components/TextEditor/TextEditorPreview'

import { App } from 'redesign/components/App'

import type { IExpandedRowProps } from './ExpandedRow.types'

import { deleteButtonStyles, previewStyles } from './ExpandedRow.module.css'

const ExpandedRow: React.FC<IExpandedRowProps> = ({
  profileId,
  experience,
  industries,
  updateExperience,
  deleteExperience
}) => {
  const { modal: { confirm } } = App.useApp()

  const [isEdit, setIsEdit] = useState(false)

  const onClickDelete = () => {
    confirm({
      title: 'Permanently delete item? You can\'t undo this.',
      icon: <ExclamationCircleOutlined />,
      okText: 'DELETE',
      cancelText: 'CANCEL',
      onOk: () => {
        deleteExperience(experience.id)
      }
    })
  }

  const onClickEdit = () => {
    setIsEdit(true)
  }

  const closeForm = () => {
    setIsEdit(false)
  }

  if (isEdit) {
    return (
      <ExperiencesForm
        initialValues={experience}
        profileId={profileId}
        closeForm={closeForm}
        industries={industries}
        updateExperience={updateExperience}
      />
    )
  }

  return (
    <>
      <div className={previewStyles}>
        <TextEditorPreview
          value={experience.highlights}
          style={{ minHeight: '50px' }}
        />
      </div>

      <Button
        danger
        type="primary"
        icon={<DeleteOutlined />}
        onClick={onClickDelete}
        className={deleteButtonStyles}
      />
      <Button icon={<EditOutlined />} onClick={onClickEdit} />
    </>
  )
}

export default ExpandedRow
