import React, { useEffect, useMemo, useState } from 'react'

import { useForm } from '../../../../../hooks/useForm'
import {
  useProfile,
  useProfileSnooze,
  useProfileStatus,
  useProfileVotes
} from '../../hooks'
import { ProfileEdit, Skeleton, App } from 'redesign/components'

import { QuickActionPanel } from 'redesign/containers/QuickActionPanel'

import { OverviewHeader } from './components/OverviewHeader'

import { Col } from 'components/Col'
import { message } from 'components/Message'
import { Row } from 'components/Row'

import type { ProfileEditValues } from 'redesign/components/ProfileEdit/ProfileEdit.types'

import { readProfileResume } from 'services/profile'

import { useProfileDuplicates } from '../../hooks/useProfileDuplicates'
import { useProfileXProfiles } from '../../hooks/useProfileXProfiles'

import { useFeatureFlags } from 'redesign/hooks/useFeatureFlags'
import { useOpenXProfile } from 'redesign/hooks/useOpenXProfile'
import { usePartnerRoleCandidates } from 'redesign/hooks/usePartnerRoleCandidates'
import { useProfileFxtStatus } from 'redesign/hooks/useProfileFxtStatus'
import { useProfileId } from 'redesign/hooks/useProfileId'
import { useProfileXTeamerStatus } from 'redesign/hooks/useProfileXTeamerStatus'
import { useRequestXProfile } from 'redesign/hooks/useRequestXProfile'
import { useUser } from 'redesign/hooks/useUser'

import { getLocationFieldValue } from 'utils/format-timezone'

import type { Country } from 'redesign/types/Country'
import type { SubTaxonomySkill } from 'redesign/types/ParsedResume'
import type ParsedResume from 'redesign/types/ParsedResume'
import type { SocialAccounts } from 'redesign/types/Profile'

import { BaseData, MainData, SummaryData } from './components'
import type { OverviewProps } from './Overview.types'

import {
  mainColStyles,
  mainRowStyles,
  overviewSectionStyles
} from './Overview.module.css'

const Overview = ({ setSelectedTab }: OverviewProps) => {
  const { modal: { confirm } } = App.useApp()

  const [form] = useForm()
  const [isEditMode, setIsEditMode] = useState(false)
  const [isLoadingResume, setIsLoadingResume] = useState(false)
  const [parsedResume, setParsedResume] = useState<ParsedResume | null>(null)

  const { data: user } = useUser()
  const { profileId } = useProfileId()
  const { data: status, isLoading: isProfileStatusLoading } = useProfileStatus()
  const {
    data: xProfiles,
    createXProfile,
    prepareXProfilePayload
  } = useProfileXProfiles()
  const { requestXProfile } = useRequestXProfile()

  const { openXProfile } = useOpenXProfile()

  const {
    data: profile,
    isLoading: isLoadingProfile,
    updateProfile,
    updateProfileIsLoading
  } = useProfile()

  const {
    fxtStatus,
    isLoading: isFxtStatusLoading,
    updateProfileFxtStatus
  } = useProfileFxtStatus({ profileId: Number(profileId) })

  const {
    data: xTeamerStatus,
    isLoading: isXTeamerStatusLoading
  } = useProfileXTeamerStatus({ profileId: Number(profileId) })

  const { duplicates, isDuplicatesLoading, markDuplicates } =
    useProfileDuplicates({ profileId: Number(profileId) })

  const roleCandidatesParams = {
    profile: profileId
  }
  const { data: roleCandidates, isLoading: roleCandidatesIsLoading } =
    usePartnerRoleCandidates({ params: roleCandidatesParams })

  const { data: featureFlags } = useFeatureFlags()

  const {
    data: profileVotes,
    isLoading: votesIsLoading,
    createProfileUpVote,
    createProfileDownVote,
    updateProfileVote,
    deleteProfileVote
  } = useProfileVotes()

  const {
    createProfileSnooze,
    deleteProfileSnooze,
    createSnoozeIsLoading,
    deleteSnoozeIsLoading,
    createSnoozeError
  } = useProfileSnooze()

  const closeProfileForm = () => {
    setIsEditMode(false)
  }

  const handleOpenXProfile = (id?: number, type?: string) => {
    openXProfile(id || (profileId as number), type)
  }

  const handleCreateXProfile: () => Promise<void> =
  async () => {
    // Shouldn't normally happen but this prevents
    // from creating profiles with almost no data populated.
    if (!parsedResumeData) {
      return
    }

    const payload = prepareXProfilePayload({
      profile,
      parsedResume: parsedResumeData,
      allSkills
    })
    try {
      const { data } = await createXProfile(payload)
      openXProfile(data.id, 'id')
    } catch (error) {
      message.error('X-Profile creation failed')
    }
  }

  const handleRequestXProfile = () => {
    confirm({
      title: 'Request the X-Profile for this Profile?',
      content: (
        <div>This is a profile-wise request, not for a specific role. In case you need a specific role, please request it from the role candidate record.</div>
      ),
      onOk: () => {
        if (profileId) {
          requestXProfile({ profileId })
        }
      }
    })
  }

  const socialAccounts: SocialAccounts = {
    github: profile.githubAccount,
    linkedin: profile.linkedinAccount,
    stackoverflow: profile.stackoverflowAccount
  }

  useEffect(() => {
    const loadResume = async () => {
      setIsLoadingResume(true)
      try {
        const { data } = await readProfileResume(profile.id)

        if (data) {
          setParsedResume(data)
        }
        setIsLoadingResume(false)
      } catch {
        setParsedResume(null)
        setIsLoadingResume(false)
      }
    }

    const isDefinedResume = profile.resume && Object.keys(profile.resume).length > 0

    if (isDefinedResume) {
      loadResume()
    } else {
      setIsLoadingResume(false)
      setParsedResume(null)
    }
  }, [profile.id, profile.resume])

  // Experiences
  const { data: parsedResumeData } = parsedResume || {}

  const taxonomies = useMemo(
    () =>
      parsedResumeData?.SkillsData.reduce<
        Array<{ skills: SubTaxonomySkill[] }>
      >((prev, taxonomy) => {
        const subTaxonomies = taxonomy.SubTaxonomies.map(subTaxonomy => ({
          skills: subTaxonomy.Skills
        }))

        return [...prev, ...subTaxonomies]
      }, []),
    [parsedResumeData]
  )

  const allSkills = useMemo(
    () =>
      taxonomies?.reduce<SubTaxonomySkill[]>(
        (prev, taxonomy) => [...prev, ...(taxonomy.skills || [])],
        []
      ),
    [taxonomies]
  )

  const formInitialValues = {
    fullName: profile.fullName,
    email: profile.email,
    xteamEmail: profile.xteamEmail,
    location: getLocationFieldValue(profile.country, profile.timezoneOffset),
    referral: profile.referral,
    linkedinAccount: profile.linkedinAccount,
    githubAccount: profile.githubAccount,
    stackoverflowAccount: profile.stackoverflowAccount,
    rateRange: profile.rateRange,
    about: profile.about,
    summary: profile.summary,
    country: (profile.country as Country)?.id,
    countryEntity: profile.country as Country,
    timezoneOffset: profile.timezoneOffset,
    sourcedFrom: profile.sourcedFrom,
    source: profile.source
  }

  const handleUpdateProfile = (values: ProfileEditValues) => {
    updateProfile(values, { onSuccess: closeProfileForm })
  }

  const shouldShowProfileSkeleton = Boolean(isLoadingProfile ||
    isProfileStatusLoading ||
    isFxtStatusLoading ||
    isXTeamerStatusLoading ||
    roleCandidatesIsLoading ||
    isLoadingResume)

  if (shouldShowProfileSkeleton) {
    return (
      <div className={overviewSectionStyles}>
        <Skeleton active />
      </div>
    )
  }

  if (isEditMode) {
    return (
      <ProfileEdit
        form={form}
        isLoading={updateProfileIsLoading}
        onCancelClicked={closeProfileForm}
        initialValues={formInitialValues}
        onSaveClicked={handleUpdateProfile}
      />
    )
  }

  return (
    <div className={overviewSectionStyles}>
      <OverviewHeader
        profileSnooze={profile.profileSnooze}
        profileStatus={status}
        setIsEditMode={setIsEditMode}
        createProfileSnooze={createProfileSnooze}
        createSnoozeError={createSnoozeError}
        createSnoozeIsLoading={createSnoozeIsLoading}
        deleteProfileSnooze={deleteProfileSnooze}
        deleteSnoozeIsLoading={deleteSnoozeIsLoading}
        roleCandidates={roleCandidates}
        roleCandidatesIsLoading={roleCandidatesIsLoading}
        profile={profile}
        status={status}
        reviewer={user.id}
        votes={profileVotes}
        votesIsLoading={votesIsLoading}
        createProfileUpVote={createProfileUpVote}
        createProfileDownVote={createProfileDownVote}
        updateProfileVote={updateProfileVote}
        deleteProfileVote={deleteProfileVote}
        featureFlags={featureFlags}
        duplicates={duplicates}
        markDuplicates={markDuplicates}
        isDuplicatesLoading={isDuplicatesLoading}
        updateFxtStatus={updateProfileFxtStatus}
        isFxtStatusLoading={isFxtStatusLoading}
        isFxt={fxtStatus}
      />

      <Row style={{ marginTop: 20 }}>
        <Col className={mainColStyles}>
          <div className={mainRowStyles}>
            <BaseData
              profileImageUrl={profile?.image?.url}
              socialAccounts={socialAccounts}
              createdAt={profile.created_at}
              updatedAt={profile.updated_at}
              xProfiles={xProfiles}
              resumeMetadata={profile.resume}
              xProfileRequestedAt={profile.xProfileRequestedAt}
              xProfileRequestedBy={profile.xProfileRequestedBy?.username}
              parsedResume={parsedResume}
              handleOpenXProfile={handleOpenXProfile}
              handleCreateXProfile={handleCreateXProfile}
              handleRequestXProfile={handleRequestXProfile}
              featureFlags={featureFlags}
              isFxt={fxtStatus}
              xTeamerStatus={xTeamerStatus}
            />
            <MainData
              profile={profile}
              parsedResume={parsedResume}
              setSelectedTab={setSelectedTab}
              createdAt={profile.created_at}
              updatedAt={profile.updated_at}
              xProfiles={xProfiles}
              xProfileRequestedAt={profile.xProfileRequestedAt}
              xProfileRequestedBy={profile.xProfileRequestedBy?.username}
              handleOpenXProfile={handleOpenXProfile}
              handleCreateXProfile={handleCreateXProfile}
              handleRequestXProfile={handleRequestXProfile}
            />
          </div>

          <QuickActionPanel />
        </Col>

        <Col flex="min-content">
          <SummaryData
            summaryByCandidate={profile.about}
            summaryByCavalry={profile.summary}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Overview
