import React, { useState } from 'react'

import moment from 'moment'

import { ExpandedRow } from '../ExpandedRow'
import { RunwayModal } from '../RunwayModal'
import { SlackNotificationButton } from '../SlackNotificationButton'
import { SectionCardContent, Tooltip } from 'redesign/components'

import { Button } from 'components/Button'
import type { ColumnsType } from 'components/Table'
import { Table } from 'components/Table'

import { getDateInReadableFormat, getInDaysFormat } from 'utils/runway-helpers'

import type Runway from 'redesign/types/Runway'

import type {
  IRunwaysNormalizedValues,
  IRunwaysTableProps
} from './RunwaysTable.types'

const RunwayDurationColumn = ({
  duration
}: {
  duration: IRunwaysNormalizedValues
}) => (
  <>
    {duration.endingDate &&
      moment.utc(duration.endingDate).format('ddd D/MMM/YYYY')}
    {!duration.endingDate && <span>Not defined yet</span>}
    {duration.description && <span>({duration.description})</span>}
  </>
)

const RunwaysTable = ({
  runways,
  activeRunways,
  profileId,
  xhqLink,
  isUserManager,
  onCreate,
  onEdit,
  onDelete,
  createSlackNotification,
  duplicates
}: IRunwaysTableProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const canCreateRunway = duplicates.length
    ? Boolean(duplicates.filter(({ duplicateOf }) => duplicateOf === profileId).length)
    : true

  const columns: ColumnsType<Runway & IRunwaysNormalizedValues> = [
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: value => <input type="checkbox" disabled checked={value} />,
      align: 'center'
    },
    {
      title: 'Start',
      dataIndex: 'startAt',
      key: 'startAt',
      width: 180,
      render: value => {
        const today = moment().format('YYYY-MM-DD')
        const daysToStartFromToday = moment(value).diff(today, 'days')

        return (
          <>
            <div>{getDateInReadableFormat(value)}</div>
            {daysToStartFromToday > 0 && (
              <div>(Starting in {getInDaysFormat(daysToStartFromToday)})</div>
            )}
            {daysToStartFromToday === 0 && <div>(Starting today)</div>}
          </>
        )
      },
      sorter: {
        compare: (a, b) => moment(a.startAt).diff(b.startAt)
      }
    },
    {
      title: 'Duration',
      key: 'duration',
      defaultSortOrder: 'descend',
      render: value => <RunwayDurationColumn duration={value} />,
      sorter: {
        compare: (a, b) => moment(a.endingDate).diff(b.endingDate)
      }
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: value => value,
      sorter: {
        compare: (a, b) => a.rate - b.rate
      }
    },
    ...(isUserManager
      ? [
        {
          title: 'Slack Extended Notification',
          key: 'slackExtendedNotification',
          width: 150,
          render: (record: Runway) => (
            <SlackNotificationButton
              runway={record}
              activeRunways={activeRunways}
              xhqLink={xhqLink}
              onClick={createSlackNotification}
            />
          )
        }
      ]
      : [])
  ]

  return (
    <>
      <SectionCardContent
        title="Runways"
        actions={
          !canCreateRunway ? (
            <Tooltip title="Unable to perform action on a duplicate profile. Please use the master profile.">
              <Button type="primary" disabled>
                Create
              </Button>
            </Tooltip>
          ) : (
            <Button type="primary" onClick={openModal}>
              Create
            </Button>
          )
        }
      >
        <Table
          rowKey="id"
          expandRowByClick
          columns={columns}
          dataSource={runways}
          pagination={false}
          scroll={{ x: true }}
          expandable={{
            expandedRowRender: record => (
              <ExpandedRow
                profileId={profileId}
                runway={record}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            )
          }}
        />
      </SectionCardContent>

      <RunwayModal
        visible={isModalOpen}
        profileId={profileId}
        closeForm={closeModal}
        onCreate={onCreate}
        onEdit={onEdit}
      />
    </>
  )
}

export default RunwaysTable
