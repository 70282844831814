
import React from 'react'

import { InfoCircleFilled } from '@ant-design/icons'

import ActivationLink from 'components/ActivationLink'

import type { ILegacyPanelProps } from './LegacyPanel.types'

import { panelStyles, iconStyles } from './LegacyPanel.module.css'

const LegacyPanel = ({ profile }: ILegacyPanelProps) => {
  const isVisible = profile.activated === false

  if (!isVisible) {
    return null
  }

  return (
    <div className={panelStyles}>
      <div>
        <InfoCircleFilled className={iconStyles} />
        <span>
          Legacy users or manually created Profiles can activate their account
          here.
        </span>
      </div>
      <ActivationLink profile={profile} />
    </div>
  )
}

export default LegacyPanel
