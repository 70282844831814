import React, { useEffect, useMemo, useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { message } from 'components/Message'

import type { ProfileEditValues } from 'redesign/components/ProfileEdit/ProfileEdit.types'

import {
  readProfile,
  updateProfile as updateProfileService
} from 'services/profile'

import { useLocalStorage } from 'redesign/hooks/useLocalStorage'
import { clearStorageValue } from 'redesign/hooks/useLocalStorage/useLocalStorage'
import { useProfileId } from 'redesign/hooks/useProfileId'

import { ORIGINS } from 'utils/role-candidates-helpers'

import type { MutationOptions } from 'redesign/types/Mutation'
import type { Origin } from 'redesign/types/Origin'

import type { IUseProfileReturn } from './useProfile.types'

const useProfile = (): IUseProfileReturn => {
  const queryClient = useQueryClient()
  const { profileId } = useProfileId()
  const [localStorageValue] = useLocalStorage('origin')

  const [profileOrigin, setProfileOrigin] = useState<Origin>()

  useEffect(() => {
    if (
      profileId &&
      localStorageValue &&
      localStorageValue[profileId] &&
      localStorageValue[profileId]?.type !== ORIGINS.UNKNOWN.type
    ) {
      setProfileOrigin(localStorageValue[profileId])
    }
    return () => {
      if (profileId) {
        clearStorageValue('origin', profileId)
      }
    }
  }, [localStorageValue, profileId])

  const { data, isLoading, error } = useQuery<AxiosResponse, AxiosError>({
    queryKey: ['profile', profileId],
    queryFn: () =>
      readProfile(profileId, {
        params: {
          populate: [
            'resume',
            'image',
            'profileSnooze',
            'profileSnooze.reviewer',
            'preferredSkills',
            'preferredJobTypes',
            'profileStatus',
            'country',
            'xProfileRequestedBy',
          ],
          profileURL: true
        }
      }),
    retry: false
  })
  const profileData = useMemo(() => data?.data || {}, [data])
  const origin = useMemo(() => profileOrigin ?? undefined, [profileOrigin])

  const updateMutation = useMutation<
    AxiosResponse,
    AxiosError,
    ProfileEditValues
  >({
    mutationFn: formValues => updateProfileService(profileId, formValues),
    onSuccess: () => {
      message.success('Profile updated')
      queryClient.invalidateQueries({ queryKey: ['profile'] })
      queryClient.invalidateQueries({ queryKey: ['activityLog'] })
      queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
    },
    onError: error => {
      const { data: errorData, message: errorMessage } = error.response?.data

      switch (errorMessage) {
        case 'Duplicate entry':
          return message.error({
            content: (
              <div>
                This {errorData.emailField} is currently associated with another
                profile. Click{' '}
                <a
                  href={`/profile?id=${errorData.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>{' '}
                to view that profile.
              </div>
            )
          })

        default:
          return message.error('Update Profile failed')
      }
    }
  })

  const updateProfile = (
    values: ProfileEditValues,
    callbacks?: MutationOptions
  ) => {
    updateMutation.mutate(values, callbacks)
  }

  return {
    isLoading,
    data: profileData,
    updateProfile,
    updateProfileIsLoading: updateMutation.isLoading,
    origin,
    error
  }
}
export default useProfile
