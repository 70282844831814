import { useMemo } from 'react'

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

import { message } from 'components/Message'

import {
  getProfileDuplicates,
  markProfileDuplicates,
  resetDuplicateStatus
} from 'services/profile'

import type {
  IUseProfileDuplicatesReturn,
  ProfileDuplicatesParams,
  IMarkProfileDuplicatesParams,
  IUpdateDuplicatesParams
} from './useProfileDuplicates.types'

const useProfileDuplicates = (params: ProfileDuplicatesParams): IUseProfileDuplicatesReturn => {
  const queryClient = useQueryClient()
  const { profileId } = params

  // Get duplicates of a profile
  const { data: duplicateData, isLoading: isDuplicatesLoading } =
    useQuery({
      queryKey: ['profileDuplicates', profileId],
      queryFn: () => getProfileDuplicates(profileId)
    })
  const duplicates = useMemo(() => duplicateData?.data || [], [duplicateData])
  const duplicateCount = useMemo(() => duplicateData?.data?.length || 0, [duplicateData])

  // Mark duplicates
  const updateMutation = useMutation({
    mutationFn: ({ masterId, duplicates }: IUpdateDuplicatesParams) =>
      markProfileDuplicates(masterId, duplicates),
    onSuccess: (data, variables) => {
      const { masterId, duplicates, onSuccess } = variables
      message.success('Profile duplicates successfully marked')
      queryClient.invalidateQueries({ queryKey: ['activityLog'] })
      queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
      queryClient.invalidateQueries({ queryKey: ['profileDuplicates'] })
      queryClient.invalidateQueries({ queryKey: ['masterProfiles'] })
      queryClient.invalidateQueries({ queryKey: ['profile', masterId] })
      duplicates.forEach(profileId => queryClient.invalidateQueries({ queryKey: ['profile', profileId] }))
      onSuccess && onSuccess()
    },
    onError: () => {
      message.error('Marking profile duplicates failed')
    }
  })

  // Reset duplicate status before marking duplicates
  const resetMutation = useMutation({
    mutationFn: ({ oldGroup }: IMarkProfileDuplicatesParams) =>
      resetDuplicateStatus(oldGroup),
    onSuccess: (data, variables) => {
      const { masterId, duplicates, onSuccess } = variables
      updateMutation.mutate({ masterId, duplicates, onSuccess })
    },
    onError: () => {
      message.error('Marking profile duplicates failed')
    }
  })

  // The old group contains IDs of the current master and duplicates, which will be reset before marking the new group
  const markDuplicates = ({ oldGroup, masterId, duplicates, onSuccess }: IMarkProfileDuplicatesParams) => {
    resetMutation.mutate({ oldGroup, masterId, duplicates, onSuccess })
  }

  return {
    duplicates,
    duplicateCount,
    isDuplicatesLoading: isDuplicatesLoading || updateMutation.isLoading || resetMutation.isLoading,
    markDuplicates
  }
}

export default useProfileDuplicates
