import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { listParsedResumeExtractedData } from 'services/parsedResumeExtractedData'

import type { ParsedResumeExtractedData } from 'redesign/types/ParsedResumeExtractedData'

import type { UseParsedResumeExtractedDataProps, UseParsedResumeExtractedDataResponse } from './useParsedResumeExtractedData.types'

export const useParsedResumeExtractedData = (params: UseParsedResumeExtractedDataProps) : UseParsedResumeExtractedDataResponse => {
  const { data: parsedResumeExtractedData, isLoading, isSuccess, refetch } = useQuery({
    queryKey: ['parsedResumeExtractedData', { ...params }],
    queryFn: () => listParsedResumeExtractedData({ params }),
    enabled: Boolean(params?.parsedResume),
  })

  const data: ParsedResumeExtractedData = useMemo(() => {
    const [first] = parsedResumeExtractedData?.data || []
    return first || {}
  }, [parsedResumeExtractedData])

  return {
    data,
    isLoading,
    isSuccess,
    refetch,
  }
}
