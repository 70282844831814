import React, { useState, useEffect } from 'react'

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'

import type { MinimumRateProps } from './MinimumRate.types'

import * as labeledValueStyles from '../LabeledValue.module.css'

const MinimumRate = ({ minRate = 0 }: MinimumRateProps) => {
  const [showValue, setShowValue] = useState(false)

  useEffect(() => {
    setShowValue(Number.parseInt(minRate?.toString(), 10) === 0)
  }, [minRate])

  const value = minRate ? `$${minRate}` : '-'

  return (
    <div className={labeledValueStyles.minimumRateValue}>
      {showValue ? (
        <EyeOutlined
          onClick={() => setShowValue(false)}
          data-testid="preferences-minimum-rate-hide"
        />
      ) : (
        <EyeInvisibleOutlined
          onClick={() => setShowValue(true)}
          data-testid="preferences-minimum-rate-show"
        />
      )}
      <span
        className={showValue ? labeledValueStyles.minimumRateShown : ''}
        data-testid="preferences-minimum-rate"
      >
        {showValue ? value : 'Show value'}
      </span>
    </div>
  )
}

export default MinimumRate
