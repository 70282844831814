/* eslint-disable camelcase */
import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { getCandidateSkills } from 'services/profile'

import type { IUseCandidateSkillsReturn } from './useCandidateSkills.types'

type Props = {
  profileId?: number
}

const useCandidateSkills = (params: Props): IUseCandidateSkillsReturn => {
  const { data: skillData, isLoading } = useQuery({
    queryKey: ['candidateSkills', params],
    queryFn: () => getCandidateSkills(params.profileId),
    enabled: Boolean(params?.profileId)
  })
  const data = useMemo(() => skillData?.data || [], [skillData])

  return {
    data,
    isLoading
  }
}

export default useCandidateSkills
