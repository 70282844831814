import { monthsToYears } from '../helpers'

import type { CandidateSkillRecord } from 'redesign/types/CandidateSkill'
import type { SkillGroup } from 'redesign/types/SkillGroup'
import type { SkillTag } from 'redesign/types/SkillTag'

// Sort skills based on order: qualified -> screened -> duration -> total number
export const skillTagSorter = (a: SkillTag, b: SkillTag): number => {
  const isLarger = (value: number | null, than: number | null) =>
    Boolean(value || than) && Boolean(than === null || (value && value > than))

  switch (true) {
    case a.variant === 'qualified':
      return -1
    case b.variant === 'qualified':
      return 1
    case a.variant === 'screening':
      return -1
    case b.variant === 'screening':
      return 1
    case isLarger(a.monthsExperience, b.monthsExperience):
      return -1
    case isLarger(b.monthsExperience, a.monthsExperience):
      return 1
    case a.total > b.total:
      return -1
    case b.total > a.total:
      return 1
    default:
      return 0
  }
}

const mapTypeToVariant: {
  [K in CandidateSkillRecord['type']]: SkillTag['variant']
} = {
  screened: 'screening',
  qualified: 'qualified',
  cv: 'default',
  experience: 'default',
  legacy: 'default'
}

// Convert candidate skills to TagsList component skills
export const mapSkillToTagsList = (skill: CandidateSkillRecord): SkillTag => {
  const variant = mapTypeToVariant[skill.type]

  return {
    id: skill.id,
    title: skill.title,
    monthsExperience: skill.monthsExperience,
    total: skill.total,
    variant,
    skill
  }
}

// Group the skills by years of experience: (7+, 5+, 3+, 1+, <1, unknown)
export function getGroupedSkills (skills?: SkillTag[]) {
  const skillGroups: SkillGroup[] = []
  if (!skills) {
    return skillGroups
  }
  skillGroups.push({
    years: 7,
    skills: skills.filter(
      skill => Number(monthsToYears(skill.monthsExperience)) >= 7
    )
  })
  skillGroups.push({
    years: 5,
    skills: skills.filter(skill =>
      [5, 6].includes(Number(monthsToYears(skill.monthsExperience)))
    )
  })
  skillGroups.push({
    years: 3,
    skills: skills.filter(skill =>
      [3, 4].includes(Number(monthsToYears(skill.monthsExperience)))
    )
  })
  skillGroups.push({
    years: 1,
    skills: skills.filter(skill =>
      [1, 2].includes(Number(monthsToYears(skill.monthsExperience)))
    )
  })
  skillGroups.push({
    years: 0,
    skills: skills.filter(skill => monthsToYears(skill.monthsExperience) === 0)
  })
  skillGroups.push({
    years: 20,
    skills: skills.filter(
      skill => monthsToYears(skill.monthsExperience) === null
    )
  })

  return skillGroups
}
