import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { getProfileXTeamerStatus } from 'services/profile'

import type {
  IUseProfileXTeamerStatusProps,
  IUseProfileXTeamerStatusReturn
} from './useProfileXTeamerStatus.types'

const useProfileXTeamerStatus = ({ profileId }: IUseProfileXTeamerStatusProps): IUseProfileXTeamerStatusReturn => {
  const { data: xTeamerStatus, isLoading } = useQuery({
    queryKey: ['profileXTeamerStatus', profileId],
    queryFn: () => getProfileXTeamerStatus(profileId)
  })
  const [data] = useMemo(() => xTeamerStatus?.data || [], [xTeamerStatus])

  return {
    data,
    isLoading
  }
}

export default useProfileXTeamerStatus
