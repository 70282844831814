import React, { useState } from 'react'

import { TeamOutlined } from '@ant-design/icons'

import { ActionButtons } from '../ActionButtons'
import { ActionButtonsRedesign } from '../ActionButtonsRedesign'
import { MasterDuplicateIndicator } from '../MainData/components/MasterDuplicateIndicator'
import { ProfileVotes } from '../MainData/components/ProfileVotes'
import { RelatedProfilesModal } from '../MainData/components/RelatedProfilesModal'

import StatusTag from 'components/ProfileStatusTag/StatusTag'
import { Row } from 'components/Row'
import { Tag } from 'components/Tag'

import type { OverviewHeaderProps } from './OverviewHeader.types'

import {
  votingControlsStyles,
  statusTagStyles,
  tagsRowStyles
} from './OverviewHeader.module.css'

const OverviewHeader = ({
  setIsEditMode,
  createProfileSnooze,
  createSnoozeError,
  createSnoozeIsLoading,
  deleteProfileSnooze,
  deleteSnoozeIsLoading,
  roleCandidates,
  roleCandidatesIsLoading,
  profile,
  status,
  reviewer,
  votes,
  votesIsLoading,
  createProfileUpVote,
  createProfileDownVote,
  updateProfileVote,
  deleteProfileVote,
  featureFlags,
  duplicates,
  markDuplicates,
  isDuplicatesLoading,
  updateFxtStatus,
  isFxtStatusLoading,
  isFxt
}: OverviewHeaderProps) => {
  const [isRelatedProfileModalOpen, setIsRelatedProfileModalOpen] =
    useState<boolean>(false)

  const isMarkDuplicatesEnabled = Boolean(featureFlags?.RELEASE_MARK_DUPLICATES)
  const isFxtImprovementsEnabled = Boolean(featureFlags?.RELEASE_FXT_IMPROVEMENTS)

  return (
    <>
      <Row justify="space-between">
        <Row justify="start" align="middle" className={tagsRowStyles}>
          <div className={statusTagStyles}>
            <StatusTag status={status.status} />
          </div>

          {profile.referral && (
            <Tag color="blue" style={{ height: 22 }}>
              <TeamOutlined />
              Referral
            </Tag>
          )}

          {isMarkDuplicatesEnabled && duplicates.length > 0 && (
            <MasterDuplicateIndicator
              isMaster={profile.duplicateOf === null}
              showDetails={() => setIsRelatedProfileModalOpen(true)}
            />
          )}

          <div className={votingControlsStyles}>
            <ProfileVotes
              votes={votes}
              reviewer={reviewer}
              votesIsLoading={votesIsLoading}
              createProfileUpVote={createProfileUpVote}
              createProfileDownVote={createProfileDownVote}
              updateProfileVote={updateProfileVote}
              deleteProfileVote={deleteProfileVote}
            />
          </div>
        </Row>

        <div>
          {(isMarkDuplicatesEnabled || isFxtImprovementsEnabled) ? (
            <ActionButtonsRedesign
              profile={profile}
              duplicates={duplicates}
              setIsEditMode={setIsEditMode}
              profileStatus={status}
              createProfileSnooze={createProfileSnooze}
              createSnoozeIsLoading={createSnoozeIsLoading}
              createSnoozeError={createSnoozeError}
              deleteProfileSnooze={deleteProfileSnooze}
              deleteSnoozeIsLoading={deleteSnoozeIsLoading}
              roleCandidates={roleCandidates}
              roleCandidatesIsLoading={roleCandidatesIsLoading}
              markProfileDuplicates={markDuplicates}
              markDuplicatesIsLoading={isDuplicatesLoading}
              featureFlags={featureFlags}
              updateFxtStatus={updateFxtStatus}
              isFxtStatusLoading={isFxtStatusLoading}
              isFxt={isFxt}
            />
          ) : (
            <ActionButtons
              profileSnooze={profile.profileSnooze}
              setIsEditMode={setIsEditMode}
              profileStatus={status}
              createProfileSnooze={createProfileSnooze}
              createSnoozeIsLoading={createSnoozeIsLoading}
              createSnoozeError={createSnoozeError}
              deleteProfileSnooze={deleteProfileSnooze}
              deleteSnoozeIsLoading={deleteSnoozeIsLoading}
              roleCandidates={roleCandidates}
              roleCandidatesIsLoading={roleCandidatesIsLoading}
            />
          )}
        </div>
      </Row>

      {featureFlags?.RELEASE_MARK_DUPLICATES && isRelatedProfileModalOpen && (
        <RelatedProfilesModal
          profiles={[
            ...[
              {
                id: profile.id,
                email: profile.email,
                fullName: profile.fullName,
                duplicateOf: profile.duplicateOf
              }
            ],
            ...duplicates.sort((a, b) =>
              a.duplicateOf === null
                ? -1
                : b.duplicateOf === null
                  ? 1
                  : a.id - b.id
            )
          ]}
          onClose={() => {
            setIsRelatedProfileModalOpen(false)
          }}
        />
      )}
    </>
  )
}

export default OverviewHeader
