import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

// Services
import { listJobApplicationsReviewers } from 'services/jobApplications'

// Types
import type { IUseJobApplicationsReviewers } from './useJobApplicationsReviewers.types'

const useJobApplicationsReviewers = (): IUseJobApplicationsReviewers => {
  const { data: reviewersData, isLoading } = useQuery({
    queryKey: ['reviewers'],
    queryFn: listJobApplicationsReviewers
  })
  const data = useMemo(() => reviewersData?.data || [], [reviewersData])

  return {
    data,
    isLoading
  }
}
export default useJobApplicationsReviewers
